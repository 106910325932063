// priority of configuration
// 1. baked in through webpack build (process.env.*)
// 2. window configured via server
// 3. fallback value, depends on field, other window location, or localhost

const windowUrl = window.location.protocol + "//" + window.location.host;

// from api server
const injected = window.APPLICATION_CONFIG || {};

/** magic value WINDOW_URL gets transformed into the windows current url */
function transformWindowUrl(value) {
  return value === "WINDOW_URL" ? windowUrl : value;
}

function toWebsocket(url) {
  // note, handles https to wss conversion by leaving trailing 's' intact
  return url.replace(/^http/, "ws");
}

const assetUrl = transformWindowUrl(process.env.ASSET_URL || injected.assetUrl) || windowUrl;
const apiUrl =
  transformWindowUrl(process.env.API_URL || injected.apiUrl) ||
  (assetUrl === windowUrl ? "http://localhost:9000" : windowUrl);
const notificationsApiUrl = transformWindowUrl(
  process.env.NOTIFICATIONS_API_URL || injected.notificationsApiUrl || "https://workflow.dev.k8s.chatmeter.com"
);
const notificationsSocketUrl = toWebsocket(notificationsApiUrl);
// const themeApiURL = `http://localhost:8081`;

const themeApiURL = process.env.THEME_API_URL || injected.themeApiUrl || `https://themes.chatmeter.com`;
const apiSocketUrl = toWebsocket(apiUrl);
const version = process.env.VERSION || "SNAPSHOT";

// TODO - callers reference the config instead of window.assetUrl
window.assetUrl = assetUrl;

window.printChatmeterVersion = () => {
  console.log(`running chatmeter version ${version}`);
};

const cssRendererUrl =
  process.env.CSS_RENDERER_URL || injected.cssRendererUrl || "https://services.live.k8s.chatmeter.com/css-inliner";

const unleashApiUrl =
  process.env.UNLEASH_URL || injected.unleashUrl || "https://unleash.live.k8s.chatmeter.com/api/frontend";

const unleashApiKey = process.env.UNLEASH_API_KEY || injected.unleashApiKey || "secret";

export default {
  assetUrl,
  apiUrl,
  apiSocketUrl,
  themeApiURL,
  isDevelopment: process.env.NODE_ENV === "development",
  notificationsApiUrl,
  notificationsSocketUrl,
  version,
  cssRendererUrl,
  unleashApiUrl,
  unleashApiKey,
};
