import * as React from "react";
import moment from "moment";
import { PageFilter } from "../../../components/filters/PageFilter";
import { DateRangeSelector } from "../../../components";
import socialPostFiltersStore, { filterBindingDefaults, filterBindings } from "./SocialPostFiltersStore";
import * as mobx from "mobx";
import ProvidersDropDown from "../../../services/providers/ProvidersDropDown";
import ProvidersStore from "../../../services/providers/ProvidersStore";
import { observer } from "mobx-react";
import { SentimentSelector } from "../../pulse/filters/filters";
import { SavedKeywordSearch } from "./SavedKeywordSearch";
import { AdvancedPostFiltersStateTransform } from "./AdvancedPostFilters";
import { BindWithUrl } from "../../../util/BindWithUrl";
import { FieldDescriptors } from "../../../util/urlSync";
import SentimentIcon from "../../../modules/pulse/components/SentimentIcon";
import * as PropTypes from "prop-types";
import { SavedFilterSetContainer } from "../../savedFilterSets/SavedFilterSetContainer";
import "./socialPostFiltersContainers.scss";

const store = socialPostFiltersStore;

const today = moment().utc().startOf("day");
export const dateRangeOptions = [
  { count: 1, name: "Last Day", dateRangeType: "day" },
  { count: 7, name: "Last Week", dateRangeType: "week" },
  { count: 30, name: "Last Month", dateRangeType: "month" },
  { count: 90, name: "Last 3 Months", dateRangeType: "3months" },
  { count: 365, name: "Last Year", dateRangeType: "year" },
].map(({ count, name, dateRangeType }) => {
  return { from: moment(today).subtract(count, "days").startOf("day"), to: today.endOf("day"), name, dateRangeType };
});

function toSocialProvider(providerConstant) {
  // just uppercase to title case
  const lower = providerConstant.toLowerCase();
  return providerConstant[0] + lower.slice(1);
}

function fromSocialProvider(socialProvider) {
  // just title case to uppercase
  return socialProvider.toUpperCase();
}

export const SocialDateRangeFilter = ({ secondary = true } = {}) => (
  <PageFilter
    value={{ from: store.filters.start, to: store.filters.end, dateRangeType: store.filters.dateRangeType }}
    onValueChanged={({ from, to, dateRangeType }) => {
      store.setFilters({ start: moment(from), end: moment(to), dateRangeType: dateRangeType || "selected" });
    }}
    secondary={secondary}
  >
    {({ value, onValueChanged }) => {
      const { from, to } = value;
      let selected = dateRangeOptions.find(
        (range) => range.to.diff(to, "days") === 0 && range.from.diff(from, "days") === 0
      );
      const option = selected || { from, to, dateRangeType: "selected" };
      return (
        <DateRangeSelector
          required
          selected={option}
          namedOptions={dateRangeOptions}
          onSelected={onValueChanged}
          disabledDays={{ after: new Date() }}
        />
      );
    }}
  </PageFilter>
);

export const SocialProviderFilter = () => (
  <SocialProvidersSelector
    selected={(mobx.toJS(store.filters.chatterProvider) || []).map(fromSocialProvider)}
    onSelected={(provider) => store.setFilters({ chatterProvider: provider.map(toSocialProvider) })}
  />
);

const SocialProvidersSelector = observer((props) => {
  return (
    <ProvidersDropDown
      right={true}
      columnWeight={2}
      className="d-inline-block"
      providers={mobx.toJS(ProvidersStore.socialProviders)}
      placeholder={<span>All Providers</span>}
      correctSelection
      eager
      {...props}
    />
  );
});

export const SocialSentimentFilter = () => (
  <SentimentSelector
    multi
    selected={store.filters.sentimentClassification}
    renderMultiSelected={(xs) => (
      <div>
        {xs.map((x) => (
          <SentimentIcon classification={x} />
        ))}
      </div>
    )}
    onSelected={(val) => store.setFilters({ sentimentClassification: val })}
  />
);

export const SocialKeywordFilter = ({ secondary = true } = {}) => (
  <PageFilter
    secondary={secondary}
    value={store.filters.text}
    onValueChanged={(text) => {
      store.setFilters({ text });
    }}
  >
    {({ value, onValueChanged }) => {
      return (
        <div>
          <SavedKeywordSearch value={value} onValueChanged={onValueChanged} />
        </div>
      );
    }}
  </PageFilter>
);

export const SocialAdvancedPostFilters = () => (
  <AdvancedPostFiltersStateTransform filters={mobx.toJS(store.filters)} onFiltersChanged={store.setFilters} />
);

export const SocialPostFiltersContainer = observer(
  class SocialPostFiltersContainer extends React.Component {
    fieldDescriptors = FieldDescriptors.build(FieldDescriptors.applyDefaults(filterBindings, filterBindingDefaults));

    static propTypes = {
      withoutSubFilters: PropTypes.bool,
    };

    render() {
      return (
        <React.Fragment>
          <BindWithUrl observable={store.filters} fieldDescriptors={this.fieldDescriptors} />

          <SavedFilterSetContainer
            savedFilterSetFilters={store.savedFilterSetFilters}
            filters={[SocialProviderFilter, SocialSentimentFilter, SocialAdvancedPostFilters]}
          />

          {!this.props.withoutSubFilters && <SocialPostSubFiltersContainer />}
        </React.Fragment>
      );
    }
  }
);

export const SocialPostSubFiltersContainer = observer(
  class SocialPostSubFiltersContainer extends React.Component {
    render() {
      return (
        <React.Fragment>
          <div className="card rounded-0-top py-0 bg-transparent">
            {/*
         I know these look weird. The page filter manager grabs props from the child,
         so these can't be components, they're just render methods
         */}
          </div>
        </React.Fragment>
      );
    }
  }
);
