import SessionStore from "../../../services/session/SessionStore";
import * as mobx from "mobx";
import { debounce } from "lodash";
import * as UrlFragmentSearch from "../../../util/UrlFragmentSearch";
import { mapUserInfo } from "./metrics/MetricsProvider";
import Rudderstack from "./metrics/Rudderstack";

const QUEUE_PROCESSOR_TIMER = 3000;

let providers = [];

let lazyInstall = async () => {
  let triggerInstalled = () => {};
  let onceInstalled = new Promise((res) => (triggerInstalled = res));
  lazyInstall = () => onceInstalled;
  const { analytics } = window.APPLICATION_CONFIG || {};
  const { rudderstack, rudderstackDataPlane } = analytics || {};
  const pendingProviders = [].concat(
    rudderstack && rudderstackDataPlane ? new Rudderstack(rudderstack, rudderstackDataPlane) : []
  );
  const maybeInstalled = await Promise.all(
    pendingProviders.map((p) => {
      return Promise.resolve(p.install())
        .then(() => p)
        .catch((x) => {
          console.error("failed to install " + p.constructor.name, x);
          return undefined;
        });
    })
  );
  providers = maybeInstalled.filter((x) => x);
  triggerInstalled();
};

/** tracks navigation changes into url for primary navigation */
let pathQueue = [];
let previousPath;

const onHashChange = () => {
  pushPath();
  pushSearchParams();
};

// Only send alphanumeric to help with filtering in analytics.
function cleanButtonText(string = "") {
  return string.replace(/[^0-9a-z ]/gi, "").trim();
}

// Only records click events if elements are either
// 1. A button
// 2. An anchor tag without an href (href's are handled by navigationEvent)
// 3. Any element or parent element that has a data-analyticsclick attribute
// 4. An input of type button or type submit

const elementWhitelist = ["BUTTON", "A"];

function clickListener(e) {
  if (e.composedPath === undefined) return;
  const targetAndParents = e.composedPath();
  const maybeValidElements = targetAndParents
    .filter((element) => {
      const specificallyTagged = !!element.dataset?.analyticsclick || !!element.dataset?.analyticstrack; // Sometimes we navigate the url when we launch a modal, which breaks our interaction model, so track those as a click. Example + Create Task button in workflow.
      const allowedInput = element.type === "button" || element.type === "submit";
      const allowedElement = elementWhitelist.includes(element.tagName) && !element.href; // exclude link clicks, those are tracked as navigations
      return specificallyTagged || allowedInput || allowedElement;
    })
    .map((x) => {
      return {
        name: x.dataset?.analyticsclick || x.title || x.ariaLabel || x.value || cleanButtonText(x.innerText) || x.id,
        data: !!x.dataset?.analyticsdata ? JSON.parse(x.dataset.analyticsdata) : undefined,
      };
    });

  const maybeElement = maybeValidElements[0];
  if (maybeElement) {
    recordClickEvent(maybeElement.name, maybeElement.data);
  }
}

export function recordClickEvent(name, additionalProperties = {}) {
  const pathAndQuery = UrlFragmentSearch.getPathAndQuery();

  recordEvent("clickEvent", {
    name: name,
    path: pathAndQuery.pathname,
    ...additionalProperties,
  });
}

const pushPath = () => {
  const pathAndQuery = UrlFragmentSearch.getPathAndQuery();
  const search = UrlFragmentSearch.getQueryObject();
  if (!previousPath || previousPath.pathname !== pathAndQuery.pathname) {
    pathQueue.push({
      pathname: pathAndQuery.pathname,
      ...search,
    });
    pushWhenQuiescent();
    previousPath = pathAndQuery;
  }
};

const pushWhenQuiescent = debounce(() => {
  // only the last path is not skipped
  pathQueue.forEach((event, i) => {
    recordEvent("navigation", {
      skipped: i + 1 < pathQueue.length,
      path: event.pathname,
      search: event.search,
    });
  });
  // reset
  pathQueue = [];
}, QUEUE_PROCESSOR_TIMER);

//tracks the parameter changes, debounces to three seconds
let previousPathname = "";
let previousSearch = {};

const pushSearchParams = debounce(() => {
  const pathname = UrlFragmentSearch.getPathAndQuery().pathname;
  const search = UrlFragmentSearch.getQueryObject();
  if (pathname === previousPathname) {
    /**
     *
     * @returns {Object} object with the current value of all changed url parameters (including "undefined" for
     * parameters that have been nulled out
     */
    const getModifiedSearch = () => {
      const changedKeys = Object.keys({ ...search, ...previousSearch }).filter((k) => {
        return previousSearch[k] !== search[k];
      });
      // return the current value of all changed keys
      return changedKeys.map((k) => ({ [k]: search[k] || "PARAMETER_REMOVED" })).reduce((a, b) => ({ ...a, ...b }), {});
    };

    const modifiedSearch = getModifiedSearch();
    const anySearchChanged = Object.keys(modifiedSearch).length;

    if (anySearchChanged) {
      recordEvent("queryChanged", {
        path: pathname,
        search,
      });
    }
  }

  previousPathname = pathname;
  previousSearch = search;
}, 3000);

let disposeMobxReaction;

export function install() {
  uninstall();

  disposeMobxReaction = mobx.reaction(
    () => SessionStore.user,
    async (user) => {
      window.removeEventListener("hashchange", onHashChange);
      window.removeEventListener("click", clickListener);
      if (user) {
        await lazyInstall();
        window.addEventListener("hashchange", onHashChange);
        window.addEventListener("click", clickListener, { capture: true, passive: true });
        providers.forEach((p) => {
          try {
            const userIdentity = user.impersonatedByUser
              ? `${user.impersonatedByUser.username}_impersonating_${user.username}`
              : user.username;
            p.identify(userIdentity, mapUserInfo(user));
          } catch (e) {
            console.error("failed to identify to " + p.constructor.name, e);
          }
        });
        onHashChange(); // push the initial page on login/page load
      }
    },
    {
      fireImmediately: true,
    }
  );
}

export function uninstall() {
  window.removeEventListener("hashchange", onHashChange);
  window.removeEventListener("click", clickListener);
  disposeMobxReaction && disposeMobxReaction();
}

/** sends the event to all configured metrics sites */
export async function recordEvent(name, properties = {}) {
  await lazyInstall(); // make sure events can be recorded
  providers.forEach((p) => {
    try {
      p.recordEvent(name, properties);
    } catch (e) {}
  });
}
