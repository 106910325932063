import React, { Fragment } from "react";
import { DropdownItem } from "reactstrap";
import {
  DropdownButtonSelectorToggle,
  DropdownMenuContainer,
  DropdownPaneOptions,
  DropdownSelectorRenderer,
  SelectorRenderer,
} from "./DropdownSelectorRenderer";
import { IconToTheSide, Spinner } from "..";
import Waypoint from "react-waypoint";
import PropTypes from "prop-types";

export class SearchToSelectRenderer extends React.Component {
  static propTypes = {
    loading: PropTypes.bool,
    loadingMore: PropTypes.bool,
    loadingMessage: PropTypes.any,
    onQueryChange: PropTypes.func,
    query: PropTypes.string,
    onKeyDown: PropTypes.func,
    getMoreSearchResults: PropTypes.func,
    activeIndex: PropTypes.number,
    renderHeader: PropTypes.any,
    renderHeaderWithinSearchToSelect: PropTypes.bool,
    renderToggle: PropTypes.any,
    renderAroundSelectOptions: PropTypes.any,
    renderOptions: PropTypes.any,
    renderMenu: PropTypes.any,
    ...SelectorRenderer.propTypes,
  };

  get selectorRendererProps() {
    // splitting and adding the test id back again
    const splitProps = SelectorRenderer.splitProps(this.props)[0];
    splitProps.dataTestId = this.props.dataTestId;
    return splitProps;
  }

  Header = (props) => {
    if (this.props.renderHeader && !this.props.renderHeaderWithinSearchToSelect) {
      return this.props.renderHeader(this.props);
    }
    return <SearchToSelectInput {...this.props} />;
  };

  AroundSelectOptions = ({ children }) => {
    if (this.props.renderAroundSelectOptions) {
      return this.props.renderAroundSelectOptions(this.props);
    }
    return (
      <div style={{ overflowY: "auto", paddingBottom: "10px" }}>
        {children}
        <SearchToSelectLoadMore {...this.props} />
      </div>
    );
  };

  Options = (props) => {
    if (this.props.renderOptions) {
      return this.props.renderOptions(this.props);
    }
    const { loading, activeIndex, options } = this.props;
    const selectorRendererProps = this.selectorRendererProps;
    const { getKey, activeKey } = selectorRendererProps;
    return (
      <Fragment>
        {loading ? (
          <DropdownItem disabled toggle={false}>
            <Spinner size="md" timeout={200} center />
          </DropdownItem>
        ) : (
          <DropdownPaneOptions
            {...selectorRendererProps}
            activeKey={activeKey ? activeKey : options[activeIndex] && getKey(options[activeIndex])}
          />
        )}
      </Fragment>
    );
  };

  Menu = (props) => {
    if (this.props.renderMenu) {
      return this.props.renderMenu(this.props);
    }
    return (
      <DropdownMenuContainer
        {...props}
        positionFixed={this.props.positionFixed}
        right={this.props.right}
        className="search-to-select-menu"
        style={{
          width: "100%",
          maxWidth: "450px",
          ...(props.isOpen ? { display: "flex", flexDirection: "column" } : {}),
        }}
      />
    );
  };

  render() {
    return (
      <DropdownSelectorRenderer
        {...this.selectorRendererProps}
        style={this.props.style}
        renderHeader={this.Header}
        renderToggle={this.Toggle}
        renderAroundSelectOptions={this.AroundSelectOptions}
        renderMenu={this.Menu}
        renderOptions={this.Options}
        className={this.props.className}
      />
    );
  }
}

export class SearchToSelectInput extends React.Component {
  static propTypes = SearchToSelectRenderer.propTypes;
  render() {
    const { onQueryChange, onKeyDown, query } = this.props;

    return (
      <div>
        {!!this.props.renderHeader &&
          this.props.renderHeaderWithinSearchToSelect &&
          this.props.renderHeader(this.props)}
        <div
          className="input-group"
          style={{
            padding: "1rem .8rem",
          }}
        >
          <input
            type="text"
            autoFocus
            className="form-control"
            onChange={onQueryChange}
            onKeyDown={onKeyDown}
            value={query}
            data-testid="input-text-providers"
          />
          <div className="input-group-append">
            <div className="input-group-text">
              <i className="far fa-search" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export class Toggle extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.renderToggle) {
      return this.props.renderToggle(this.props);
    }
    const { loading, loadingMessage } = this.props;
    return (
      <DropdownButtonSelectorToggle
        {...{
          ...props,
          overrideButtonContent: !loading ? undefined : (
            <IconToTheSide
              side="right"
              icon={<Spinner style={{ marginTop: "2px", float: "right" }} timeout={0} size="sm" />}
            >
              {loadingMessage}
            </IconToTheSide>
          ),
        }}
      />
    );
  }
}

export class SearchToSelectLoadMore extends React.Component {
  static propTypes = SearchToSelectRenderer.propTypes;

  render() {
    const { loadingMore, loading, getMoreSearchResults, options } = this.props;
    return (
      <React.Fragment>
        {loadingMore && <Spinner size="sm" timeout={0} center />}
        {!loadingMore && !loading && <Waypoint onEnter={getMoreSearchResults} />}
      </React.Fragment>
    );
  }
}
