// FOLDY LAYOUT
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@QUJ=!^zs|!LwN@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@Dz~`     .;r,`_\D@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@#o!'           ,^;`,|K@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@K\~`               .;!',|K@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@#y!'                     :!:,*A@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@dT~`                         ';~~?q@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@Qm^'                              `:~~<U@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@gn;`                                   '~;*6@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@q|,                                        `,;*U@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@Qm^'                    '~                       '~?XQ@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@Qy;`  rSRWDZL,            q@I                        `~|U@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@NI~`  =Q@y!~!zkJ            `W@o`                         ,*X@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@Ns~`   j@N'                     t@Qn~        ,?7`             '=jdQ@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@8t;`    }@D`                       `*AQ@Q%bdWQ@Qdz`              `.'~^J6#@@@@@@@@@@@@@@@@@@
// @@@@@@@#n;``    '@@.                            '~!rr!~.                  ````.`'~!zUN@@@@@@@@@@@@@@
// @@@@@#n;`````   !@Q                                                      `.````````.':;LEgQ@@@@@@@@@
// @@@Bu;````````   ^:               ~!`               ;#Q7                 `.`````````````.,;?SRQ@@@@@
// @Qo!.`````````                   ~@@@,              #@@@_               `.``````````````````.,;|E8@@
// NL'````````````                  ;@@@j              6@@@X               ````````````````````````.~Lg
// z,`````````````                   W@@g              `d@@y              ```````````````````````````~z
// s_``````````````                  `UQ*                `.              ````````````````````````````~7
// m;``````````````                                                      `.``````````````````````````!5
// g?```````````````                                                    ````````````````````````````.|g
// @7'```````````````                                                   ````````````````````````````:7@
// @k^```````````````                                    .*}'          `````````````````````````````+X@
// @@v'```````````````               ;~              ,+y#@BI`         `````````````````````````````,TQ@
// @@q=````````````````             `U@@DS}tttsuyEbB@@g5<'            `````````````````````````````^w@@
// @@@J,```````````````                ,!|7Ijjjs7?^_`                `````````````````````````````'\Q@@
// @@@g?````````````````                                            `.````````````````````````````!Z@@@
// @@@@j;````````````````                                           ````````````````````````````.,TQ@@@
// @@@@@f;'.``````````````                                        ```````````..```.`..........',~LW@@@@
// @@@@@@QgdAUEajIz\L*=^!;;;~~~~~~~~~~~~;;;;;;;;;;;;;;!!^^^^^r++<|i\T7zJtsn}fjjyyy5ZmmwwEEEhkUqDN@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
// https://objectshowfanonpedia.fandom.com/wiki/Foldy

import * as React from "react";
import { useContext, useState, createContext, useEffect, useLayoutEffect, useCallback } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { useObserver, observer } from "mobx-react";
import { AppNavigatorContext } from "../navigator/AppNavigator";
import { useNavPreferences } from "../../../uiMigration/navPreferences";
import RegisterPortal from "../../../components/portals/RegisterPortal";
import { FILTER_TOGGLE, PAGE_FILTERS } from "../navigator/navigatorPortalNames";
import { PageFiltersContext } from "./PageFilters";
import VerticalPageFiltersLayout from "./VerticalPageFiltersLayout";
import { renderNav, NavItem } from "./NavRenderingUtils";
import PageHeaderLayout from "./PageHeaderLayout";
import Button from "../../../components/buttons/Button";
import { CSSTransition } from "react-transition-group";
import variables from "../../../../src/components/bootstrap4/includes/_core-variables.scss";
import UsePageTitle from "./UsePageTitle";
import PageTitle from "../../../components/typography/PageTitle";
import { HelpIcon, NameChip } from "../../../components";
import HeaderHelpCenterButton from "./HeaderHelpCenterButton";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import { NotificationList } from "../../../services/notifications/NotificationList";
import { useRef } from "react";
import * as mobx from "mobx";

const backButtonContainerStyle = { height: "65px" };

export function FoldyLayout({ children, className }) {
  const prefs = useObserver(() => useNavPreferences());
  const navigationContext = useContext(AppNavigatorContext);
  const { setFiltersOpen, isFiltersOpen, setHasFilters, hasFilters } = useContext(FoldyContext);
  const headerButtonRef = useRef(null);
  const pageHeaderStickyWrapperRef = useRef(null);
  const navScrollerContainerRef = useRef(null);
  const navHeaderContainerRef = useRef(null);

  const [navHasScrollbar, setNavHasScrollbar] = useState(null);

  useLayoutEffect(() => {
    // prevents a show filter bug that occurs when going to another sitemap in desktop width, then resizing to mobile then returning to first sitemap page
    if (window.innerWidth <= 768) {
      setFiltersOpen(false);
    }
    // start with a blank slate
    setHasFilters(false);
    if (window.ResizeObserver) {
      const windowScrollbarResizeObserver = new ResizeObserver(() => {
        const container = document.querySelector(".foldy");
        if (container) {
          container.style.setProperty(
            "--scrollbar-width",
            `${window.innerWidth - document.documentElement.clientWidth + 1}px`
          );
        }
        checkSidebarWidth();
      });
      // const nav = navScrollerContainerRef.current;
      // const sidebarObserver = new ResizeObserver(() => {

      // });
      // sidebarObserver.observe(nav);
      windowScrollbarResizeObserver.observe(document.body);
      return () => {
        // sidebarObserver.unobserve(nav);
        windowScrollbarResizeObserver.unobserve(document.body);
      };
    }
  }, [navigationContext.activeNode?.path]);

  function checkSidebarWidth() {
    const nav = navScrollerContainerRef?.current;
    nav?.style.setProperty(
      "--nav-scrollbar-width",
      `${
        document.querySelector(".foldy-nav")?.getBoundingClientRect()?.width -
        document.querySelector(".foldy-nav")?.clientWidth
      }px`
    );
    const navHasScrollbar = nav?.scrollHeight > nav?.clientHeight;
    // Check if nav sidebar has a scrollbar
    if (navHasScrollbar) {
      setNavHasScrollbar(true);
    } else {
      setNavHasScrollbar(false);
    }
  }

  // Adds a shadow to the Page Header/Page Commands bar when scroll position > 0 and removes if < 0.
  useLayoutEffect(() => {
    let lastKnownScrollPosition = 0;
    let ticking = false;
    const current = pageHeaderStickyWrapperRef?.current;

    function addOrRemoveShadow() {
      lastKnownScrollPosition = window.scrollY;
      if (!ticking) {
        requestAnimationFrame(function () {
          if (window.scrollY > 1) {
            current?.classList?.add("sticky-page-header-wrapper-shadow");
          } else {
            current?.classList?.remove("sticky-page-header-wrapper-shadow");
          }
          ticking = false;
        });

        ticking = true;
      }
    }

    window.addEventListener("scroll", addOrRemoveShadow);
    return () => window.removeEventListener("scroll", addOrRemoveShadow);
  }, []);

  // Adds a shadow to navigation container
  useLayoutEffect(() => {
    let lastKnownScrollPosition = 0;
    let ticking = false;
    const current = navScrollerContainerRef.current;

    function addOrRemoveShadow() {
      lastKnownScrollPosition = current.scrollTop;
      if (!ticking) {
        requestAnimationFrame(function () {
          if (current.scrollTop > 1) {
            navHeaderContainerRef.current.classList?.add("foldy-nav-header-wrapper-shadow");
          } else {
            navHeaderContainerRef.current.classList?.remove("foldy-nav-header-wrapper-shadow");
          }
          ticking = false;
        });
        ticking = true;
      }
    }

    current.addEventListener("scroll", addOrRemoveShadow);
    return () => current.removeEventListener("scroll", addOrRemoveShadow);
  }, []);

  useLayoutEffect(() => {
    if (prefs.hideApplicationBar) {
      window.document.body.classList.add("hide-application-bar");
      document
        .querySelector(".foldy")
        ?.style.setProperty("--header-button-container-height", `${headerButtonRef.current.offsetHeight}px`);
      return () => window.document.body.classList.remove("hide-application-bar");
    }
  }, [JSON.stringify(headerButtonRef.current?.style)]);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const transitionDuration = parseInt(variables.transitionDuration);

  function renderPills() {
    //If there's only one tab, there's no point in showing tabs. Only have 2 levels of nav for settings and make horizontal tabs
    const nav = (navigationContext.isDrillInSiteMap ? navigationContext.navL2 : navigationContext.navL3).flatMap((x) =>
      x.isSection ? x.children : [x]
    );

    if ((nav.length || 0) < 2 || navigationContext.activeNode.isDrillInPage) {
      return null;
    } else {
      return (
        <div className="d-flex flex-row scroll-container position-relative card-spacer-bottom">
          <nav className="nav nav-pills no-wrap">
            {nav.map(({ path, title, eventName, active, icon, exact }) => {
              return (
                <NavItem
                  containerClassName="scroll-tab"
                  path={path}
                  key={path}
                  active={active}
                  eventName={eventName}
                  exact={exact}
                >
                  {typeof title === "function" ? title() : title}
                </NavItem>
              );
            })}
          </nav>
        </div>
      );
    }
  }

  function HeaderButtons() {
    return (
      <>
        {!prefs.hideHelpCenter && <HeaderHelpCenterButton />}
        {!prefs.hideNotifications && <NotificationList />}
        <div className="d-flex align-items-center">
          <Link
            to="/admin/v2/user"
            className="header-button text-decoration-none"
            onClick={() => {
              // Set a back button if not on the primary sitemap
              if (navigationContext.siteMapId === 0) {
                navigationContext.setPreviousPrimarySitemapLocation(
                  navigationContext.location.pathname + navigationContext.location.search
                );
              }
            }}
          >
            <div className="d-flex align-items-center">
              <i className="fas fa-cog" />
            </div>
          </Link>
        </div>
        <UncontrolledDropdown data-analyticsclick="Appbar User Dropdown">
          <DropdownToggle caret={false} tag="div" className="d-flex align-items-center header-button">
            <NameChip
              name={`${prefs.userFirstName}`}
              className="m-0"
              overrideBgColor="var(--component-active-bg)"
              overrideTextColor="var(--component-active-color)"
            />
          </DropdownToggle>
          <DropdownMenu right className="top-nav-settings-dropdown">
            <DropdownItem
              disabled
              style={{
                color: "var(--body-color)",
              }}
            >
              <h5>{prefs.userFullName}</h5>
              {prefs.userEmail}
            </DropdownItem>
            <DropdownItem divider />
            {!prefs.hideLogout && (
              <DropdownItem href="#/user/logout">
                <div className="d-flex flex-row algin-items-center">
                  <i className="fas fa-sign-out-alt d-flex align-items-center content-spacer-right"></i> Log Out
                </div>
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }

  const navMarginStyle = prefs.hideApplicationBar ? { marginBottom: "var(--header-button-container-height)" } : {};

  return (
    <PageFiltersContext.Provider
      value={{
        PageFiltersLayout: VerticalPageFiltersLayout,
      }}
    >
      <div id="foldyLayout" className={classnames("main-container d-flex flex-column fill-height", className)}>
        {/* Begin Application Bar */}
        {!prefs.hideApplicationBar && (
          <>
            <Alert key={"alert"} />
            <div className="d-flex align-items-center w-100 foldy-layout-main-header-top-wrapper sticky-top">
              <div className="main-header-top foldy-layout-main-header-top d-flex flex-column align-items-center w-100">
                <div className="container-fluid d-flex align-items-center">
                  {!prefs.hideLogo && (
                    <span
                      className="my-2"
                      style={{
                        height: "39px",
                      }}
                    >
                      <Link to="/" className="logo" title="home" data-analyticsclick="Appbar Logo">
                        <img className="navbar-brand-logo" src={prefs.logoUrl} alt="logo" />
                      </Link>
                    </span>
                  )}
                  <div className="d-flex align-items-center ml-auto">
                    <HeaderButtons />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {/* End Application Bar */}
        <div className="d-flex flex-column flex-1">
          <div className="d-flex flex-1">
            <div className="d-flex flex-1 flex-column flex-md-row foldy-layout">
              <div className="sticky-top sticky-nav-wrapper">
                <CSSTransition
                  in={mobileNavOpen}
                  timeout={transitionDuration}
                  classNames="foldy-mobile-nav"
                  addEndListener={(node) => {
                    // use the css transitionend event to mark the finish of a transition
                    node.addEventListener(
                      "transitionend",
                      () => {
                        if (window.innerWidth <= 768) {
                          checkSidebarWidth();
                        }
                      },
                      false
                    );
                  }}
                >
                  <div className="sticky-top foldy-nav-wrapper h-100">
                    <div
                      ref={navHeaderContainerRef}
                      className="d-flex flex-row align-items-center position-fixed"
                      style={{
                        width: `calc(${variables.sidebarWidth} ${
                          navHasScrollbar ? "+ var(--scrollbar-width) - 1px" : ""
                        })`,
                      }}
                    >
                      {navigationContext.isDrillInSiteMap && (
                        <div
                          className="d-flex align-items-center flex-row align-center card-spacer-padding-left card-spacer-padding-top card-spacer-padding-bottom"
                          style={backButtonContainerStyle}
                        >
                          <Link
                            className="text-body card-spacer-right"
                            style={{
                              fontSize: "1.5rem",
                            }}
                            data-analyticsclick="Sitemap Back"
                            to={navigationContext.previousPrimarySitemapLocation}
                          >
                            <i className="fas fa-arrow-left" />
                          </Link>
                          <h2 className="page-title">{navigationContext.siteMapTitle}</h2>
                        </div>
                      )}
                      <Button
                        color="link"
                        className="mobile-nav-close-button text-decoration-none text-body card-spacer-padding-right card-spacer-padding-top card-spacer-padding-bottom"
                        onClick={() => setMobileNavOpen(false)}
                        data-analyticsclick="Mobile Close Nav"
                      >
                        Close
                      </Button>
                    </div>

                    <div
                      ref={navScrollerContainerRef}
                      className={`foldy-nav ${navigationContext.isDrillInSiteMap ? "drill-in-foldy-nav" : ""}`}
                    >
                      <nav className="nav nav-pills nav-stacked d-flex flex-column flex-1 tabs-area cursor-default foldy-nav-inner">
                        <div className={`${navigationContext.isDrillInSiteMap ? "" : "my-3"}`}>
                          <div
                            data-analyticscontainerid="nav"
                            style={{ ...navMarginStyle }}
                            className={prefs.hideApplicationBar ? "card-spacer-padding-bottom" : ""}
                          >
                            {renderNav(navigationContext)}
                          </div>
                          {prefs.hideApplicationBar && (
                            <div
                              ref={headerButtonRef}
                              className="d-flex flex-row-reverse position-fixed card-padding card-spacer-top justify-content-between header-buttons-in-nav"
                              style={{
                                bottom: 0,
                                left: 0,
                                width: variables.sidebarWidth,
                              }}
                            >
                              <HeaderButtons />
                            </div>
                          )}
                        </div>
                      </nav>
                    </div>
                  </div>
                </CSSTransition>
              </div>
              <div className="d-flex flex-column flex-1">
                <div
                  ref={pageHeaderStickyWrapperRef}
                  style={navigationContext.activeNode.title === "Reviews" ? { zIndex: "1" } : null}
                  className="d-flex flex-row flex-wrap align-items-center card-spacer-padding sticky-page-header-wrapper"
                >
                  <div className="d-flex flex-column flex-row flex-1">
                    <div className="d-flex flex-row align-items-center flex-1">
                      <div className="mobile-nav-toggle floating-commands card-spacer-right rounded">
                        <Button
                          color="link"
                          icon="fas fa-bars"
                          className=""
                          aria-label="Open Nav"
                          onClick={() => {
                            setMobileNavOpen(true);
                          }}
                        />
                      </div>
                      <PageHeaderLayout />
                      {!navigationContext.activeNode.hasPageTitleOverride && (
                        <UsePageTitle>
                          <div className="d-flex flex-row align-items-center">
                            <PageTitle>{navigationContext.activeNode.title}</PageTitle>
                            {navigationContext.activeNode.helpIconMessage ? (
                              <HelpIcon
                                style={{ textAlign: "left" }}
                                message={navigationContext.activeNode.helpIconMessage}
                              />
                            ) : null}
                          </div>
                        </UsePageTitle>
                      )}
                      <div className="ml-auto">
                        {/* Toggle will only show up when RenderToPortal is rendered in the filter component */}
                        <RegisterPortal id={FILTER_TOGGLE} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row flex-1">
                  <div className="d-flex flex-column flex-1">
                    <CSSTransition in={isFiltersOpen} timeout={transitionDuration} classNames="width-container">
                      <div
                        id="contentArea"
                        className={`card-spacer-padding-left card-spacer-padding-right content-area content-area-bottom-spacer width-container ${
                          hasFilters ? "has-filters" : "has-no-filters"
                        } ${isFiltersOpen ? "filters-open" : "filters-closed"}`}
                      >
                        {renderPills()}
                        {children}
                      </div>
                    </CSSTransition>
                  </div>
                </div>
              </div>
              <div className="sticky-top sticky-filter-wrapper">
                <CSSTransition in={isFiltersOpen} timeout={transitionDuration} classNames="foldy-filters">
                  <div
                    className={`sticky-top filters-wrapper foldy-filters ${
                      hasFilters ? "has-filters" : "has-no-filters"
                    } ${isFiltersOpen ? "filters-open" : "filters-closed"}`}
                  >
                    <RegisterPortal
                      id={PAGE_FILTERS}
                      tagProps={{
                        className: `vertical-filter-grid flex-column filters-area rounded-0`,
                      }}
                    />
                  </div>
                </CSSTransition>
              </div>

              {/* The sidebar overlays were getting too coupled, simplify for future-proofing */}
              {/* Mobile nav overlay */}
              <CSSTransition in={mobileNavOpen} timeout={transitionDuration} classNames="mobile-nav-overlay">
                <div
                  className="transparent-overlay cursor-pointer"
                  onClick={() => {
                    //Close if background is clicked.
                    setMobileNavOpen(false);
                  }}
                ></div>
              </CSSTransition>

              {/* Mobile filters overlay */}
              <CSSTransition in={isFiltersOpen} timeout={transitionDuration} classNames="mobile-filter-overlay">
                <div
                  className="transparent-overlay cursor-pointer"
                  onClick={() => {
                    //Close if background is clicked.
                    setFiltersOpen(false);
                  }}
                ></div>
              </CSSTransition>
            </div>
          </div>
        </div>
      </div>
    </PageFiltersContext.Provider>
  );
}

export const FoldyContext = createContext({
  setFiltersOpen: () => {},
  isFiltersOpen: false,
  setHasFilters: () => {},
  hasFilters: false,
});

export const FoldyContextProvider = ({ children }) => {
  const [isFiltersOpen, setFiltersOpen] = useState(() => window.innerWidth > 768);
  const [hasFilters, setHasFilters] = useState(false);

  useEffect(() => {
    // Body class To handle tooltips/etc
    window.document.body.classList.add("foldy");

    // Safari-only namespace for sticky scrolling quirk (affects filters container)
    // Cool guy Chrome has both "Safari" and "Chrome" in its UA string :(
    const isChrome = navigator.userAgent.indexOf("Chrome") > -1;
    const isSafari = navigator.userAgent.indexOf("Safari") > -1;
    if (!isChrome && isSafari) {
      window.document.body.classList.add("is-safari");
    }

    return () => {
      window.document.body.classList.remove("foldy");
      window.document.body.classList.remove("is-safari");
    };
  }, []);

  return (
    <FoldyContext.Provider
      value={{
        setFiltersOpen,
        isFiltersOpen,
        setHasFilters,
        hasFilters,
      }}
    >
      {children}
    </FoldyContext.Provider>
  );
};

// parent component seems to remount. Use global state
const alertState = mobx.observable({ show: false });

const Alert = observer(function Alert() {
  return (
    <>
      {!alertState.show ? null : (
        <div className={"d-flex align-items-center w-100 foldy-layout-main-header-top-wrapper"}>
          <div
            className="main-header-top foldy-layout-main-header-top d-flex flex-column align-items-center w-100"
            style={{ height: "auto" }}
          >
            <div className="alert alert-warning m-1 p-4">
              <button
                onClick={() => (alertState.show = false)}
                type="button"
                className="close"
                data-dismiss="alert"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <i className="fas fa-exclamation-triangle"></i> We’re currently experiencing connectivity issues with
              Facebook and cannot import or publish data. We are in contact with Facebook to resolve the issue as
              quickly as possible. Rest assured your data is safe and will be available when the issue is resolved.
            </div>
          </div>
        </div>
      )}
    </>
  );
});
